<template>
  <AuctionListItemInfoElement v-if="type || startDate" class="w-full">
    <AuctionTypeIcon
      v-if="type"
      :type="type"
      :class="{
        'bg-success': type === 'NETLIVE' && auction.inActiveHotbidPhase,
        'animation-shake text-success': auction.inActiveHotbidPhase
      }"
    />

    <p v-if="auction.inActiveHotbidPhase" class="text-sm text-success">
      {{ $t('auction-list.in-hotbid') }}
    </p>
    <StartDateCalendarPopper v-else v-bind="auction">
      <span v-if="isMainPage">
        {{ `${getDayName(startDate, { lang: locale, weekday: 'short' })} ` }}
      </span>
      <span>{{
        formatStartDate(startDate, { showFullDate: isMainPage, $t })
      }}</span>
    </StartDateCalendarPopper>
  </AuctionListItemInfoElement>
</template>

<script lang="ts" setup>
import { getDayName } from '@autobid/ui/utils/date/getDayName'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { formatStartDate } from '@autobid/ui/utils/auctions/formatStartDate'
import { inject } from 'vue'
import AuctionTypeIcon from '../../shared/AuctionTypeIcon.vue'
import StartDateCalendarPopper from '../../shared/StartDateCalendarPopper.vue'
import AuctionListItemInfoElement from './AuctionListItemInfoElement.vue'

const auction = inject<DisplayingAuction>('auction')
const { type, startDate } = auction

const isMainPage = inject<boolean>('isMainPage')
const { locale } = useI18n()
</script>
